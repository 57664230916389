import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const GraficaArea = () => {
  const chartContainerRef = useRef(null);

  useEffect(() => {
    // Crear el gráfico en el contenedor
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#000',
      },
      grid: {
        vertLines: {
          color: '#e0e0e0',
        },
        horzLines: {
          color: '#e0e0e0',
        },
      },
      timeScale: {
        borderColor: '#e0e0e0',
      },
    });

    // Añadir una serie de área
    const areaSeries = chart.addAreaSeries({
      topColor: 'rgba(33, 150, 243, 0.5)',  // Color de la parte superior del área
      bottomColor: 'rgba(33, 150, 243, 0)', // Gradiente inferior del área
      lineColor: '#2196f3',                 // Color de la línea
      lineWidth: 2,                         // Ancho de la línea
    });

    // Datos de ejemplo
    const data = [
        { time: '2023-09-01', value: 50 },
        { time: '2023-09-02', value: 55 },
        { time: '2023-09-03', value: 53 },
        { time: '2023-09-04', value: 58 },
        { time: '2023-09-05', value: 61 },
        { time: '2023-10-01', value: 20 },
        { time: '2023-10-02', value: 10 },
        { time: '2023-10-03', value: 45 },
        { time: '2023-10-04', value: 70 },
        { time: '2023-10-05', value: 50 },
        { time: '2023-11-01', value: 38 },
        { time: '2023-11-02', value: 80 },
        { time: '2023-11-03', value: 32 },
        { time: '2023-11-04', value: 45 },
        { time: '2023-11-05', value: 34 },
    ];

    // Establecer los datos de la serie de área
    areaSeries.setData(data);

    // Limpiar el gráfico cuando el componente se desmonta
    return () => chart.remove();
  }, []);

  return <div ref={chartContainerRef} style={{ width: '100%', height: '300px' }} />;
};

export default GraficaArea;