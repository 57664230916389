import React from "react";
import "./ManualCategoryCircle.scss";
import { useNavigate } from "react-router-dom";

const ManualCategoryCircle = ({ img = "", url = "", title = "" }) => {
    const navigate = useNavigate();
    return (
        <div className="ManualCategoryCircle-container">
            <div
                className="ManualCategoryCircle"
                onClick={() => {
                    navigate(url);
                }}
            >
                <img src={img} alt=""></img>
            </div>
            <div className="title">{title}</div>
        </div>
    );
};
export default ManualCategoryCircle;
