import React from "react";
import CardView from "../../components/molecules/CardView/CardView";
import Table from "../../components/organisms/Table/Table";
import columns from '../../assets/jsons/BusinessDefeated.json'
const BusinessDefeated = () => {
    const data = [
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
        {
            nombre_negocio: "Tech Innovators",
            nombre_propietario: "Carlos López",
            ultimo_pago: "2022-01-15",
            ultimo_contrato: "Gold",
            numero_contrato: "564525",
            numero_negocio: "NO. 67",
        },
       
    ];
    return (
        <>
            <CardView>
                <div className="BusinessDefeated">
                <Table columns={columns} data={data} />
                </div>

            </CardView>

        </>
    )
}
export default BusinessDefeated