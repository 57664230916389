//styles
import "./CardForm.scss";

// Assets
import MovilImage from "../../../assets/icons/icono_login.png";

const CardForm = ({ className = "", children, title, text }) => {
    return (
        <>
            <div className={`CardForm ${className}`}>
                <div className="cnt-header">
                    <img src={MovilImage} alt="" className="movil-image" />
                    <div className="ctn-titles">
                        <h3>{title}</h3>
                        {text && <p>{text}</p>}
                    </div>
                </div>
                {children}
            </div>
        </>
    );
};

export default CardForm;
