import React from "react";

//Styles
import "./Button.scss";

const Button = ({ className = "", text, onClick, type, icon }) => {
    return (
        <button
            className={`Button ${className} ${icon ? "btn-icon" : "ic-alxl"}`}
            onClick={onClick}
            type={type}
        >
            {icon && <img src={icon} alt="" className="b-imageIcon" />}
            {text}
        </button>
    );
};

export default Button;
