/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

//Styles
import "./SideMenu.scss";

//assets
import Burger from "../../../assets/images_delete/burger.jpg";
import menuData from "../../../assets/jsons/sideMenu.json";
import Close from "../../../assets/icons/sideMenu/icon_menu_cerrar_act.png";
import iconHamburguesa from "../../../assets/icons/sideMenu/icono_menu_desplegable_act.png";

//Utilities
import { encriptar } from "../../../utiles/encriptacion.js";
import { useStoreRt } from "../../../zustand/ZustandContext.jsx";

const SideMenu = ({ className }) => {
    const local = useLocation();

    //useStore
    const { openAside, setOpenAside, setOverAside, overAside, imageSett } = useStoreRt();

    //States para el estado del movil
    const [sideMovil, setSideMovil] = useState(false);

    const logOut = () => {
        const key = process.env.REACT_APP_CLAVE_INCRIPTACION;
        const auth = encriptar("false", key);
        localStorage.setItem("auth", auth);
        window.location.href = "/";
    };

    const handleAsideComplete = () => {
        if (window.innerWidth > 767) {
            setOpenAside(!openAside);
        } else {
            setSideMovil(!sideMovil);
        }
    };

    const handleEntry = () => {
        if (!openAside && window.innerWidth > 767) {
            setOverAside(true);
        }
    };

    const handleLeave = () => {
        if (!openAside && window.innerWidth > 767) {
            setOverAside(false);
        }
    };

    useEffect(() => {
        if (sideMovil) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [sideMovil]);

    const handleHidenSide = () => {
        setSideMovil(false);
    };

    return (
        <>
            <aside className={`Aside ${className} `}>
                <div
                    className={`aside-inside ${openAside ? "aside-open" : ""} ${
                        overAside ? "aside-open-over" : ""
                    } ${sideMovil ? "show-aside-movil" : ""}`}
                >
                    <div
                        className={`a-cnt-btn ${
                            openAside ? "a-cnt-move-btn" : ""
                        } 
                        ${overAside ? "a-cnt-move-btn-over" : ""} ${
                            sideMovil ? "a-cnt-movil-bnt" : ""
                        }`}
                    >
                        <button
                            className={`btn-close-side`}
                            onClick={handleAsideComplete}
                        >
                            <img
                                src={iconHamburguesa}
                                alt=""
                                className="icon-abur"
                            />
                        </button>
                    </div>
                    <div
                        className={`container-aside`}
                        onMouseEnter={handleEntry}
                        onMouseLeave={handleLeave}
                    >
                        <div
                            className={`cnt-banner ${
                                openAside ? "cnt-open-banner" : ""
                            } ${overAside ? "cnt-open-banner" : ""}`}
                        >
                            <img
                                // src="https://cdn-icons-png.flaticon.com/512/5277/5277459.png"
                                src={imageSett ? imageSett : Burger}
                                alt=""
                                className={`ban-logo ${
                                    openAside ? "ban-open-logo" : ""
                                } ${overAside ? "ban-open-logo" : ""}`}
                            />
                        </div>
                        <div className={`cnt-options`}>
                            <ul className={`o-lu`}>
                                {menuData.map((_, idx) => (
                                    <li
                                        key={idx}
                                        className={`o-li`}
                                        onClick={handleHidenSide}
                                    >
                                        <NavLink
                                            to={_.url}
                                            className={`o-nav ${
                                                _.url === local.pathname
                                                    ? "active"
                                                    : ""
                                            } `}
                                        >
                                            <img
                                                src={require(`../../../assets/icons/sideMenu/${_.icon}`)}
                                                alt=""
                                                className={`o-img ${
                                                    _.url === local.pathname
                                                        ? "active_image"
                                                        : ""
                                                }`}
                                            />
                                            <span
                                                className={`opt-menu-sp ${
                                                    openAside
                                                        ? "o-opa-menu"
                                                        : ""
                                                } ${
                                                    overAside
                                                        ? "o-opa-menu"
                                                        : ""
                                                }`}
                                            >
                                                {_.title}
                                            </span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                            <button className="btn-logOut" onClick={logOut}>
                                <img src={Close} alt="" />
                                <span
                                    className={`b-span ${
                                        openAside ? "b-span-opa" : ""
                                    } ${overAside ? "b-span-opa" : ""}`}
                                >
                                    CERRAR SESIÓN
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};
export default SideMenu;
