import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
// Styles
import "./RecoverPassword.scss";

// Components
import Input from "../../components/atoms/Input/Input";
import CardForm from "../../components/atoms/CardForm/CardForm";
import Button from "../../components/atoms/Button/Button";
import BackgroundCard from "../../components/molecules/BackgroundCard/BackgroundCard";

//Interfaces
// import { validateData } from "../../utilities/ValidateEntries";

const RecoverPassword = () => {
    const navigate = useNavigate();
    const [empty, setEmpty] = useState({});
    const [emailData, setEmailData] = useState({
        email: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.currentTarget;
        setEmailData((prevent) => ({
            ...prevent,
            [name]: value,
        }));

        setEmpty({});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(typeof emailData)
        navigate("/verification-code");
    };

    return (
        <>
            <BackgroundCard className="RecoverPasswordView">
                <CardForm title="Recuperar cuenta" text="Actualiza tus accesos">
                    <form onSubmit={handleSubmit} className="container-access">
                        <div className="cnt-form">
                            <div className="cnt-header">
                                <p>
                                    ¡Ingresa tu correo electrónico que
                                    registraste!{" "}
                                </p>
                            </div>
                            <Input
                                text="Correo electrónico"
                                type="email"
                                name="email"
                                placeholder="Correo electrónico"
                                onChange={handleChange}
                                className="em-cls"
                                clasInput={
                                    empty.email === "required"
                                        ? "input-errors"
                                        : ""
                                }
                            />
                        </div>
                        <div className="cnt-btn-access">
                            <Button
                                type="button"
                                text="Cancelar"
                                className="cancel"
                                onClick={() => navigate("/")}
                            />
                            <Button
                                type="submit"
                                text="Enviar código"
                                className="degrad"
                            />
                        </div>
                    </form>
                </CardForm>
            </BackgroundCard>
        </>
    );
};

export default RecoverPassword;
