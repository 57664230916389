import React, { useRef, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Registrar elementos para que chart.js funcione correctamente
ChartJS.register(ArcElement, Tooltip, Legend);

const GraficaPastel = () => {
  const chartRef = useRef(null);

  const data = {
    labels: ['Rojo', 'Azul', 'Amarillo'],
    datasets: [
      {
        label: '# de votos',
        data: [12, 19, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Para que se ajuste dinámicamente al tamaño del contenedor
    responsive: true,
    plugins: {
      legend: {
        position: 'top', // Posiciona la leyenda arriba
      },
      tooltip: {
        enabled: true, // Muestra tooltips
      },
    },
  };

  useEffect(() => {
    // Redimensionar el gráfico si el contenedor cambia de tamaño
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div style={{ position: 'relative', height: '100%' }}>
        <Pie ref={chartRef} data={data} options={options} />
      </div>
    </div>
  );
};

export default GraficaPastel;