import React, { useState } from "react";

import "./ListPlans.scss";

//Assets
import Button from "../../components/atoms/Button/Button";
import CreatePlan from "./CreatePlan/CreatePlan";
import CardView from "../../components/molecules/CardView/CardView";
import Table from "../../components/organisms/Table/Table";
import { columns, data } from "./ListPlans.model";
import Input from "../../components/atoms/Input/Input";
import AnimatedCard from "../../components/molecules/AnimatedCard/AnimatedCard";
import ContainerTable from "../../components/atoms/ContainerTable/ContainerTable";

const ListPlans = () => {
    const [isSelected, setIsSelected] = useState(false);

    return (
        <CardView>
            <div className="ListPlans">
                <div className="cnt-body">
                    <div className="cnt-buttons">
                        <div className="cnt-left_add">
                            <Button
                                text={"Crear nuevo plan"}
                                className="degrad"
                                onClick={() => setIsSelected(true)}
                            />
                        </div>
                        <Input
                            // text={"Bucar plan"}
                            className="c-input"
                            type="search"
                            placeholder={"Buscar planes"}
                        />
                    </div>
                    <ContainerTable>
                        <Table columns={columns} data={data} rowPorPage={5} />
                    </ContainerTable>
                </div>

                {isSelected && (
                    <AnimatedCard setClose={setIsSelected}>
                        <CreatePlan />
                    </AnimatedCard>
                )}
            </div>
        </CardView>
    );
};

export default ListPlans;
