import React from "react";
import "./CrearNuevoNegocio.scss";
import Input from "../../components/atoms/Input/Input";
import iconNombreEmpresa from "../../assets/icons/icon_nombre_empresa_inact.png";
import iconNombreAgente from "../../assets/icons/icon_nomb_gerente_encarg_inact.png";

import iconCorreo from "../../assets/icons/icon_correo_inact.png";
import iconTelefono from "../../assets/icons/icon_telefono_inact.png";
import iconDireccion from "../../assets/icons/icon_ubicacion_inact.png";
import iconPostalCode from "../../assets/icons/icon_c_postal_inact.png";
import iconRazonSocial from "../../assets/icons/icon_razon_social_inact.png";
import iconSeleccionTipo from "../../assets/icons/icon_selec_tipo_inact.png";
import iconRFC from "../../assets/icons/icon_rfc_inact.png";
import iconPlan from "../../assets/icons/icon_plan_inact.png";
import iconDespedidaTiket from "../../assets/icons/icon_msj_ticket_inact.png";
import iconDiasGratis from "../../assets/icons/icon_dia_gratis_inact.png";
import iconAgregarSucursal from "../../assets/icons/icon_agreg_sucursales_inact.png";
import InputImagen from "../../components/atoms/InputImagen/InputImagen";
import CardView from "../../components/molecules/CardView/CardView";
import Button from "../../components/atoms/Button/Button";

const CrearNuevoNegocio = () => {

    return (
        <CardView>
            <div className="CrearNuevoNegocio">
                <div className="contactInfo">
                    <div className="containerInputImg">
                        <InputImagen></InputImagen>
                    </div>
                    <div className="descripcion">
                        <div className="title">Restaurante</div>
                        <div className="planes">
                            <div className="plan">
                                <div className="subTitlePlan">Plan 90</div>
                                <div className="descripcionPlan">Planes</div>
                            </div>
                            <div className="plan">
                                <div className="subTitlePlan">$4,600</div>
                                <div className="descripcionPlan">
                                    Precio MXN
                                </div>
                            </div>
                            <div className="plan">
                                <div className="subTitlePlan">91</div>
                                <div className="descripcionPlan">
                                    Dispositivos
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="descripcion">
                        <div className="informacionContacto">
                            <div className="title">Información Contacto</div>
                            <div className="subTitleContact">Propietario</div>
                            <div className="descripcionContact">
                                Propietario
                            </div>

                            <div className="subTitleContact">Correo</div>
                            <div className="descripcionContact">
                                demo@apprestaurant.com
                            </div>

                            <div className="subTitleContact">Teléfono</div>
                            <div className="descripcionContact">
                                00-0000-0000
                            </div>
                        </div>
                    </div>
                    <div className="descripcion">
                        <div className="title">Sucursales</div>
                    </div>
                </div>
                <div className="inputsContainer">
                    <Input
                        text={"Nombre del Negocio"}
                        icon={iconNombreEmpresa}
                    ></Input>
                    <Input
                        text={"Nombre del Dueño Encargado"}
                        icon={iconNombreAgente}
                    ></Input>
                    <Input
                        text={"Correo Electrónico"}
                        icon={iconCorreo}
                    ></Input>
                    <Input text={"Teléfono"} icon={iconTelefono}></Input>
                    <Input text={"Dirección"} icon={iconDireccion}></Input>
                    <Input text={"Código Postal"} icon={iconPostalCode}></Input>
                    <Input text={"RFC"} icon={iconRFC}></Input>
                    <Input text={"Razón Social"} icon={iconRazonSocial}></Input>
                    <Input
                        text={"Selección de Tipo"}
                        icon={iconSeleccionTipo}
                    ></Input>
                    <Input
                        text={"Mensaje Despedida Ticket"}
                        icon={iconDespedidaTiket}
                    ></Input>
                    <Input text={"Correo Usuario"} icon={iconCorreo}></Input>
                    <Input text={"Plan Seleccionado"} icon={iconPlan}></Input>
                    <Input text={"Días Gratis"} icon={iconDiasGratis}></Input>
                    <Input
                        text={"Agregar Sucursales"}
                        icon={iconAgregarSucursal}
                    ></Input>
                    <div className="butonContainer">
                        {/* <ButtonBack width="200px">GUARDAR</ButtonBack> */}
                        <Button text={"GUARDAR"} className="degrad btn-custom"/>
                    </div>
                </div>
            </div>
        </CardView>
    );
};
export default CrearNuevoNegocio;
