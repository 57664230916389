import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { desEncriptar, encriptar } from "../utiles/encriptacion";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "../index.scss";

import RoutesGuest from "./RoutesGuest/RoutesGuest";
import RoutesAuth from "./RoutesAuth/RoutesAuth";

const MainRouter = () => {
    const [auth, setAuth] = useState(false);
    const keyIncriptacion = process.env.REACT_APP_CLAVE_INCRIPTACION;
    useEffect(() => {
        const clave = keyIncriptacion;
        if (localStorage.getItem("auth")) {
            const autLocal = localStorage.getItem("auth");
            if (desEncriptar(autLocal, clave) === "false") {
                setAuth(false);
            } else {
                setAuth(true);
            }
        } else {
            localStorage.setItem("auth", encriptar(`${auth}`, clave));
        }
        // }, [localStorage.getItem("auth")]);
    }, [auth, keyIncriptacion]);

    return (
        <BrowserRouter>
            {auth ? (
                // <SideMenu setAuth={setAuth}>

                <RoutesAuth></RoutesAuth>
            ) : (
                // </SideMenu>
                <>
                    <RoutesGuest></RoutesGuest>
                </>
            )}
        </BrowserRouter>
    );
};
export default MainRouter;
