import React from "react";
import "./Select.scss";
const Select = ({ options = [], setValue, value = "", className = "" }) => {
    const handleChange = (e) => {
        const selectedOption = options.find(
            (option) => option.code.toString() === e.target.value
        );
        setValue(selectedOption);
    };
    return (
        <div className={`Select ${className}`}>
            <select value={value.code || ""} onChange={handleChange}>
                {options.map((item) => (
                    <option key={item.code} value={item.code}>
                        {item.name}
                    </option>
                ))}
            </select>
            {/* <div className="flecha"><img src={downarrow} alt="" /></div> */}
        </div>
    );
};

export default Select;
