import React from "react";
import "./Layout.scss";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../Header/Header";
import Footer from "../../molecules/Footer/Footer";
import { useStoreRt } from "../../../zustand/ZustandContext";

const Layout = ({ className = "", children }) => {
    const { openAside, overAside } = useStoreRt();
    return (
        <div
            className={`Layout ${className} ${openAside ? "close-sider" : ""} ${
                overAside ? "over-sider" : ""
            }`}
            id="layout_x1"
        >
            <Header className="Header_" />
            <SideMenu className="SideBar_" />
            <main className="View_">{children}</main>
            <Footer className="Footer" />
        </div>
    );
};

export default Layout;
