import React, { useEffect, useState } from "react";

//Assets
import Logo from "../../../assets/icons/logo_header.png";

import "./CreatePlan.scss";
import Input from "../../../components/atoms/Input/Input";
import Button from "../../../components/atoms/Button/Button";

const CreatePlan = () => {
    const [newPlan, setNewPlan] = useState({
        plan: "",
        devices: null,
        plazo: "",
        price: null,
    });

    useEffect(() => {
        document.body.classList.add("no-scroll");
    }, []);

    const eventChange = (e) => {
        const { name, value } = e.target;
        setNewPlan((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCreate = (e) => {
        e.preventDefault();
        console.log(newPlan);
    };

    return (
        <div className="CreatePlan">
            <form className="CreatePlan" onSubmit={handleCreate}>
                <header className="c-header">
                    <img src={Logo} alt="" className="c-image" />
                </header>
                <div className="c-barr">CREAR NUEVO PLAN</div>
                <div className="c-body">
                    <Input
                        text={"Nombre del plan"}
                        name="plan"
                        onChange={eventChange}
                    />
                    <Input
                        text={"Número de dispositivos"}
                        type="number"
                        name="devices"
                        onChange={eventChange}
                    />
                    <Input text={"Plazo"} name="plazo" onChange={eventChange} />
                    <Input
                        text={"Precio del plan"}
                        type="number"
                        name="price"
                        onChange={eventChange}
                    />
                </div>
                <div className="c-footer">
                    <Button
                        text={"Guardar"}
                        className="degrad c-btn"
                        type={"submit"}
                    />
                </div>
            </form>
        </div>
    );
};

export default CreatePlan;
