export const columns = [
    {
        id: 1,
        header: "Nombre del plan",
        accessor: "plan_name",
        width: "15%",
    },
    {
        id: 2,
        header: "Número de dispositivos",
        accessor: "number_devices",
        width: "8%",
    },
    { id: 3, header: "Plazo", accessor: "plazo", width: "8%" },
    { id: 4, header: "Precio", accessor: "price", width: "8%" },
    { id: 5, header: "Cantidad de clientes", accessor: "amount", width: "8%" },
];

export const data = [
    {
        id: 1,
        plan_name: "Plan 1",
        number_devices: 2,
        plazo: "30 días",
        price: "$1500",
        amount: 50,
    },
    {
        id: 2,
        plan_name: "Plan 2",
        number_devices: 7,
        plazo: "1 año",
        price: "$5000",
        amount: 150,
    },
    // {
    //     id: 3,
    //     plan_name: "Plan de la tienda del sur",
    //     number_devices: 7,
    //     plazo: "1 año",
    //     price: "$5000",
    // },
    // {
    //     id: 4,
    //     plan_name: "Plan sobrino de la hermana de monse",
    //     number_devices: 7,
    //     plazo: "1 año",
    //     price: "$5000",
    // },
];
