import React from "react";
import './CardPlan.scss'

const CardPlan = ({title = 'titulo',children = 300,numberColor = 'var(--c950)',height="160px"})=>{
    return(
        <>
            <div className="CardPlan">
            <div className="headerCard">{title}</div>
            <div className="bodyCard" style={{color:numberColor,height:height}}>{children}</div>
            </div>
        </>
    )
}
export default CardPlan