import React from "react";

//Styles
import "./ContainerTable.scss";

const ContainerTable = ({ children }) => {
    return (
        <>
            <div className="ContainerTable">{children}</div>
        </>
    );
};

export default ContainerTable;
