import React, { useState } from "react";
import defaultImagen from "../../../assets/icons/grafico_ponerr_imagen.svg";
import "./InputImagen.scss";
import { useStoreRt } from "../../../zustand/ZustandContext";

const InputImagen = () => {
    const [image, setImage] = useState(null);

    const { imageSett, setImageSett } = useStoreRt();

    const loadImage = (event) => {
        const input = event.target;
        const reader = new FileReader();

        reader.onload = function () {
            setImage(reader.result);
            setImageSett(reader.result);
        };

        if (input.files && input.files[0]) {
            reader.readAsDataURL(input.files[0]);
        }
    };

    return (
        <div
            className="image-upload-wrapper"
            onClick={() => document.getElementById("fileInput").click()}
        >
            <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={loadImage}
            />
            {imageSett || image ? (
                <img
                    src={imageSett ? imageSett : image}
                    alt="Uploaded"
                    className="show"
                />
            ) : (
                <img src={defaultImagen} alt="Uploaded" className="show" />
            )}
        </div>
    );
};

export default InputImagen;
