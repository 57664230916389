import React, { useState } from "react";

//Styles
import "./InputPassword.scss";

//assets
import Eye from "../../../assets/icons/eye.svg";
import EyeOff from "../../../assets/icons/eye_off.svg";

const InputPassword = ({
    className = "",
    text,
    name,
    value,
    placeholder,
    onChange,
    required,
}) => {
    const [inputType, setInputType] = useState(false);

    return (
        <div className={`InputPassword ${className}`}>
            {text && <span className="title-input">{text}</span>}
            <div className="container-input">
                <input
                    className={"cs-input"}
                    type={inputType ? "text" : "password"}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    required={required}
                />
                <img
                    src={inputType ? EyeOff : Eye}
                    alt=""
                    className="img-password"
                    onClick={() => setInputType(!inputType)}
                />
            </div>
        </div>
    );
};

export default InputPassword;
