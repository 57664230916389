import React from "react";
import "./AvisosAutomaticos.scss";
import CardView from "../../components/molecules/CardView/CardView";
import logo from "../../assets/icons/logo_header.png";
const AvisosAutomaticos = () => {
    return (
        <CardView>
            <div className="AvisosAutomaticos">
                <div className="headerAvisosAutomaticos">
                    <img src={logo} width={80} alt="" />
                </div>
                <CardView>
                    <div className="mensajesContainer">
                        <div className="cardContent">
                            <div className="title">AVISO</div>
                            <div className="content">
                                <div className="description">
                                    Lorem ipsum dolor sit amet, consectetuer
                                    adipiscing elit, sed diam nonummy nibh
                                    euismod tincidunt ut laoreet dolore magna
                                    aliquam erat volutpat. Ut wisi enim ad minim
                                    veniam, quis nostrud exerci tation
                                    ullamcorper suscipit lobortis nisl ut
                                    aliquip ex ea commodo consequat.
                                </div>
                                <div className="radios">
                                    <label>
                                        <input
                                            type="radio"
                                            name="option"
                                            value="1"
                                        />
                                        Opción 1
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            name="option"
                                            value="2"
                                        />
                                        Opción 2
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            name="option"
                                            value="3"
                                        />
                                        Opción 3
                                    </label>
                                </div>
                                <div className="buttonContainer">
                                    <button className="button">CANCELAR</button>
                                    <button className="button blue">
                                        ENVIAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardView>
            </div>
        </CardView>
    );
};
export default AvisosAutomaticos;
