import React, { useState } from "react";
import "./Manuals.scss";
import manuales from "../../assets/icons/icon_menu_manuales_inact.png";
import ManualCategoryCircle from "../../components/atoms/ManualCategoryCircle/ManualCategoryCircle";
import Select from "../../components/atoms/Select/Select";
import Button from "../../components/atoms/Button/Button";
import CardView from "../../components/molecules/CardView/CardView";
const Manuals = () => {
    const [selectValue, setSelectValue] = useState();
    const selectOptions = [
        { name: "opcion1", code: 1 },
        { name: "opcion2", code: 2 },
        { name: "opcion3", code: 3 },
        { name: "opcion4", code: 4 },
    ];
    console.log("selectValue", selectValue);
    return (
        <CardView>
            <div className="Manuals">
                <div className="containerCategories">
                    <ManualCategoryCircle
                        img={manuales}
                        title="MANUALES"
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="FAQ"
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="VIDEOS"
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="COMO PAGAR MEMBRESIAS"
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="ATENCIÓN PERSONALIZADA"
                    ></ManualCategoryCircle>
                </div>
                <div className="createCategory">
                    <div className="card">
                        <div className="title">CREAR CATEGORÍA</div>
                        <div className="select-container">
                            <Select
                                className="select"
                                setValue={setSelectValue}
                                value={selectValue}
                                options={selectOptions}
                            ></Select>
                        </div>
                        <div className="buttonCrear">
                            <Button
                                className="degrad buttoncREAR"
                                text={"CREAR"}
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </CardView>
    );
};
export default Manuals;
