import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

//Styles
import "./CodeInput.scss";

//Components
import Button from "../../atoms/Button/Button";

const InputField = ({
    id,
    value,
    onChange,
    nextFieldID,
    prevFieldID,
    className = "",
    classInput = "",
}) => {
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1) {
            onChange(parseInt(id.replace("code-input", "")), newValue);
            if (newValue.length === 1 && nextFieldID) {
                const nextField = document.getElementById(nextFieldID);
                nextField?.focus();
            }
        }
    };

    const handleKeyDown = (e) => {
        if (
            e.key === "Backspace" &&
            e.currentTarget.value === "" &&
            prevFieldID
        ) {
            const prevField = document.getElementById(prevFieldID);
            prevField?.focus();
        }
    };

    return (
        <div className={className}>
            <input
                className={classInput}
                id={id}
                ref={inputRef}
                type="text"
                maxLength={1}
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

const CodeInput = ({
    fields,
    setCode,
    handleSubmit,
    empty,
    setEmpty,
}) => {
    const [values, setValues] = useState(Array(fields).fill(""));
    const navigate = useNavigate();

    const handleInputChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = value.toLocaleUpperCase();
        setValues(newValues);
        setEmpty(false);
        setCode(newValues);
    };

    return (
        <div className="container-react-code">
            <form onSubmit={handleSubmit} className="container-codeInput">
                <div className="cnt-inputs">
                    {[...Array(fields)].map((_, idx) => (
                        <InputField
                            className={`container-deg ${
                                empty ? "empty-entry" : ""
                            }`}
                            classInput={`CodeInput`}
                            key={idx}
                            id={`code-input${idx}`}
                            value={values[idx]}
                            onChange={handleInputChange}
                            nextFieldID={
                                idx < fields - 1
                                    ? `code-input${idx + 1}`
                                    : undefined
                            }
                            prevFieldID={
                                idx > 0 ? `code-input${idx - 1}` : undefined
                            }
                        />
                    ))}
                </div>
                <div className="cnt-btns">
                    <Button
                        text={"Cancelar"}
                        className="cancel"
                        onClick={() => navigate("/")}
                    />

                    <Button
                        type="submit"
                        text="Validar código"
                        className="degrad"
                    />
                </div>
            </form>
        </div>
    );
};

export default CodeInput;
