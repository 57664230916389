import React from "react";

//Styles
import "./Footer.scss"

const Footer = ({ className }) => {
    return (
        <footer className={`Footer ${className}`}>
            Todos los derechos reservados
        </footer>
    );
};

export default Footer;
