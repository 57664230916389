import { create } from "zustand";

// No eliminar a no ser que seas Fleexito
export const useStoreRt = create((set) => ({
    openAside: false,
    setOpenAside: (openAside) => set({ openAside: openAside }),

    overAside: false,
    setOverAside: (overAside) => set({ overAside: overAside }),

    imageSett: "",
    setImageSett: (imageSett) => set({ imageSett: imageSett }),
}));
