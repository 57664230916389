import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const GraficaBarras = () => {
  const chartContainerRef = useRef(null);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#000',
      },
      grid: {
        vertLines: {
          color: '#e0e0e0',
        },
        horzLines: {
          color: '#e0e0e0',
        },
      },
      priceScale: {
        borderColor: '#e0e0e0',
      },
      timeScale: {
        borderColor: '#e0e0e0',
      },
    });

    const barSeries = chart.addHistogramSeries({
      color: '#26a69a',
      lineWidth: 2,
      priceFormat: {
        type: 'volume',
      },
    });

    // Datos de ejemplo
    const data = [
      { time: '2023-09-01', value: 10 },
      { time: '2023-09-02', value: 20 },
      { time: '2023-09-03', value: 15 },
      { time: '2023-09-04', value: 30 },
      { time: '2023-09-05', value: 25 },
      { time: '2023-10-05', value: 25 },
      { time: '2023-10-06', value: 25 },
      { time: '2023-10-07', value: 252 },
    ];

    barSeries.setData(data);

    return () => chart.remove();
  }, []);

  return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default GraficaBarras;