import React from "react";

// Styles
import "./SmoothContainer.scss";

const SmoothContainer = ({ children, actionState, height }) => {
    return (
        <div
            className={`SmoothContainer`}
            style={actionState ? { height: height } : { height: 0 }}
        >
            {children}
        </div>
    );
};

export default SmoothContainer;
