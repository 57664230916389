import React, { useState } from "react";

// Components
import CardForm from "../../components/atoms/CardForm/CardForm";
import CodeInput from "../../components/molecules/CodeInput/CodeInput";
import BackgroundCard from "../../components/molecules/BackgroundCard/BackgroundCard";

// Styles
import "./VerificationCode.scss";

const VerificationCode = () => {
    const [code, setCode] = useState([]);
    const [empty, setEmpty] = useState(false);
    const totalFields = 5;

    const handleSubmit = (e) => {
        e.preventDefault();

        const fullCode = code.join("");
        if (fullCode.length >= totalFields) {
            console.log(code);
        } else {
            // setEmpty(true);
        }
    };
    return (
        <>
            <BackgroundCard className="Verificationcode">
                <CardForm
                    title="Código de recuperación"
                    text="Ingresa el código enviado"
                >
                    <div className="container-code-input">
                        <CodeInput
                            fields={totalFields}
                            setCode={setCode}
                            handleSubmit={handleSubmit}
                            setEmpty={setEmpty}
                            empty={empty}
                        />
                    </div>
                </CardForm>
            </BackgroundCard>
        </>
    );
};

export default VerificationCode;
