import React from "react";

//Styles
import "./BusinessReport.scss";

//Assets
import Calendar from "../../assets/icons/calendar.svg";

//Components
import CardView from "../../components/molecules/CardView/CardView";
import Button from "../../components/atoms/Button/Button";
import Input from "../../components/atoms/Input/Input";

const BusinessReport = () => {
    return (
        <CardView>
            <div className="BusinessReport">
                <div className="b-card">
                    <div className="b-cnt-inputs">
                        <div className="b-cnt-ca">
                            <span className="sp">Desde</span>
                            <div className="b-sp-in">
                                <Input type="date" className="input-ins" />
                                <div className="b-cnt-image">
                                    <img src={Calendar} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="b-cnt-ca">
                            <span className="sp">Hasta</span>
                            <div className="b-sp-in">
                                <Input className="input-ins" type="date" />
                                <div className="b-cnt-image">
                                    <img src={Calendar} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="b-cnt-inputs"></div> */}
                    <Button text={"Generar"} className="degrad b-wi" />
                </div>
            </div>
        </CardView>
    );
};

export default BusinessReport;
