import React, { useEffect, useState } from "react";

import "./BusinessListing.scss";

//Components
import CardView from "../../components/molecules/CardView/CardView";
import Table from "../../components/organisms/Table/Table";

//Assets
import Report from "../../assets/icons/Report.svg";
import Calendar from "../../assets/icons/calendar.svg";

//Json
import Bussines from "../../assets/jsons/BusinessListing.json";
import Input from "../../components/atoms/Input/Input";
import Button from "../../components/atoms/Button/Button";
import ContainerTable from "../../components/atoms/ContainerTable/ContainerTable";
import SmoothContainer from "../../components/atoms/SmoothContainer/SmoothContainer";

const BusinessListing = () => {
    const [isSelectData, setIsSelectData] = useState(false);
    const [dataActive, setDataActive] = useState([]);
    const [dataInactive, setDataInactive] = useState([]);
    const [businessSearch, setBusinessSearch] = useState("");
    const [height, setHeight] = useState(107);
    const widthScreen = window.innerWidth;

    //Este state es para controlar el comportaiento de un div al abrir y cerrar
    const [openReport, setOpenReport] = useState(false);

    useEffect(() => {
        if (widthScreen <= 767) {
            setHeight(219);
        } else if (widthScreen <= 991) {
            setHeight(239);
        }
    }, [widthScreen]);

    useEffect(() => {
        const data_ = [
            {
                business_name: "Tech Innovators",
                owner_name: "Carlos López",
                creation_date: "2022-01-15",
                membership: "Gold",
                last_payment: "2024-07-15",
                status: true,
            },
            {
                business_name: "Green Earth",
                owner_name: "Laura Martínez",
                creation_date: "2021-05-22",
                membership: "Silver",
                last_payment: "2024-06-20",
                status: false,
            },
            {
                business_name: "Digital Solutions",
                owner_name: "Andrés Fernández",
                creation_date: "2020-11-10",
                membership: "Platinum",
                last_payment: "2024-08-01",
                status: false,
            },
            {
                business_name: "Healthy Life",
                owner_name: "María García",
                creation_date: "2019-03-30",
                membership: "Gold",
                last_payment: "2024-07-25",
                status: true,
            },
            {
                business_name: "Tech World",
                owner_name: "Pedro Sánchez",
                creation_date: "2018-08-15",
                membership: "Bronze",
                last_payment: "2024-07-10",
                status: true,
            },
        ];

        const newArray = data_.map((item) => {
            return {
                ...item,
                status: item.status === true ? "Activos" : "Inactivos",
            };
        });

        const active = newArray.filter((item) => item.status === "Activos");
        const inactive = newArray.filter((item) => item.status === "Inactivos");

        setDataActive(active);
        setDataInactive(inactive);
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        console.log(businessSearch);
    };

    useEffect(() => {
        let timer;
        if (openReport) {
            timer = setTimeout(() => {
                setOpenReport(false);
            }, 60000);
        }
        // Limpiar el temporizador si el componente se desmonta o si `openReport` cambia
        return () => clearTimeout(timer);
    }, [openReport]);

    const InterfaceReport = () => {
        return (
            <div className="InterfacesReport b-card">
                <div className="b-cnt-inputs">
                    <div className="b-cnt-ca">
                        <span className="sp">Desde</span>
                        <div className="b-sp-in">
                            <Input type="date" className="input-ins" />
                            <div className="b-cnt-image">
                                <img src={Calendar} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="b-cnt-ca">
                        <span className="sp">Hasta</span>
                        <div className="b-sp-in">
                            <Input className="input-ins" type="date" />
                            <div className="b-cnt-image">
                                <img src={Calendar} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <Button text={"Generar"} className="degrad b-wi" />
            </div>
        );
    };

    return (
        <CardView>
            <div className="BusinessListing">
                <div className="b-cnt-header">
                    <div className="b-cnt-acc">
                        <div className="b-cnt-btns">
                            <Button
                                text={"Activos"}
                                className={`b-btn ${
                                    !isSelectData ? "degrad-off" : ""
                                }`}
                                onClick={() => setIsSelectData(false)}
                            />
                            <Button
                                text={"Inactivos"}
                                className={`b-btn ${
                                    isSelectData ? "degrad-off" : ""
                                }`}
                                onClick={() => setIsSelectData(true)}
                            />
                        </div>
                        <div className="b-cnt-input">
                            <form onSubmit={handleSearch} className="b-form">
                                <Input
                                    placeholder={"Negocio"}
                                    type="search"
                                    className="b-input"
                                    onChange={(e) =>
                                        setBusinessSearch(e.target.value)
                                    }
                                />
                                <button type="submit" className="b-sea">
                                    Buscar
                                </button>
                            </form>
                        </div>
                        <Button
                            icon={Report}
                            text={"Generar reporte"}
                            className="degrad btn-report"
                            onClick={() => setOpenReport(!openReport)}
                        />
                    </div>
                </div>
                <SmoothContainer actionState={openReport} height={height}>
                    <InterfaceReport />
                </SmoothContainer>
                <ContainerTable>
                    <Table
                        columns={Bussines}
                        data={!isSelectData ? dataActive : dataInactive}
                    />
                </ContainerTable>
            </div>
        </CardView>
    );
};

export default BusinessListing;
