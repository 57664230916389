import React from "react";

//Styles
import "./Header.scss";

//Assets
import Logo from "../../../assets/icons/logo_header.png";

const Header = ({ className }) => {
    return (
        <div className={`Header ${className}`}>
            <div className="cnt-logo">
                <img src={Logo} alt="" className="h-logo" />
            </div>
            <div className="rutaActual"></div>
        </div>
    );
};
export default Header;
