import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/Login/Login";
import RecoverPassword from "../../pages/RecoverPassword/RecoverPassword";
import VerificationCode from "../../pages/Verificationcode/Verificationcode";

const RoutesGuest = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Suspense fallback={<div>Skeleton</div>}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path="/recover-password"
                element={
                    <Suspense fallback={<div>Skeleton</div>}>
                        <RecoverPassword />
                    </Suspense>
                }
            />
            <Route
                path="/verification-code"
                element={
                    <Suspense fallback={<div>Skeleton</div>}>
                        <VerificationCode />
                    </Suspense>
                }
            />
        </Routes>
    );
};
export default RoutesGuest;
